// The Main Site Object
window.Site = {

    // Init Function
    liftoff : () => {
        //Site.onClickRemoveCookieBanner()
        //Site.onLoadDisplayCookieBanner()

        Site.Menu.toggle()
        Site.Sounds.setVolume()
        Site.Sounds.play()
        Site.Slider.init()

        Site.newsletterPopup()
    },

    // Generate The Cookie Banner
    onClickRemoveCookieBanner : () => {

        if (!Site.cookieBannerButton) return;

        Site.cookieBannerButton.addEventListener('click' , () => {

            if (localStorage.banner !== 'displayed')
                localStorage.banner = 'displayed';

            // Remove The Banner
            document.querySelector('.gocciola').remove();

        })
    },

    // We need to create and display the cookie banner
    onLoadDisplayCookieBanner : () => {

        if (localStorage.banner !== 'displayed') {
            
            // Create the banner DOM 
            Site.banner = document.createElement('div');
            Site.banner.classList.add('gocciola');

            // The Left Column
            Site.bannerText = document.createElement('div');
            Site.bannerText.classList.add('gocciola-text');

            // The Right Column
            Site.bannerButton = document.createElement('div');
            Site.bannerButton.classList.add('gocciola-button');


            // We need to create the content for the two columns
            Site.bannerTextContent  = document.createElement('p')
            Site.bannerButtonContent = document.createElement('button')

            // Set the text message
            Site.bannerTextContent.innerHTML  = "We use cookies"
            Site.bannerButtonContent.innerHTML = "Ok"

            // Append the two columns to the main banner
            Site.bannerText.appendChild(Site.bannerLeftContent)
            Site.bannerButton.appendChild(Site.bannerRightContent)

            // Append the two columns to the main banner
            Site.banner.appendChild(Site.bannerText)
            Site.banner.appendChild(Site.bannerButton)

            // Append the banner to the body
            document.body.appendChild(Site.banner);

        }

    },

    Menu : {

        button    : document.querySelector('.menu-button'),
        container : document.querySelector('.nav'),
        header    : document.querySelector('#home .header'),
        main      : document.querySelector('#home main'),

        toggle : () => {
            Site.Menu.button.addEventListener('click' , () => {

                Site.Menu.button.classList.toggle('active')
                Site.Menu.container.classList.toggle('active')
                
                if (Site.Menu.header) {
                    Site.Menu.header.classList.toggle('active')
                    Site.Menu.main.classList.toggle('active')
                }

            })
        }

    },

    Sounds : {

        button : document.querySelector('.play-button'),
        tracks : document.querySelectorAll('audio'),

        setVolume : () => {
            Site.Sounds.tracks.forEach(sound => {
                sound.volume = sound.dataset.volume
            })
        },

        play : () => {
            Site.Sounds.button.addEventListener('click' , () => {
                Site.Sounds.tracks.forEach(sound => {
                    if (sound.paused) {
                        sound.play()
                        Site.Sounds.button.innerHTML = "Pause"
                    } else {
                        sound.pause()
                        Site.Sounds.button.innerHTML = "Play"
                    }
                })
            })
        }

    },

    Slider : {

        // The necessary items
        sliders         : document.querySelectorAll('.slider'),
        sliderInstances : [],

        init : () => {

            Site.Slider.sliders.forEach(slider => { Site.Slider.setupSlider(slider)})

        },

        // Init the slider
        setupSlider : (slider) => {

            // Grab the components
            slider.sliderInterval = null;
            let prev              = slider.querySelector('.slider-prev');
            let next              = slider.querySelector('.slider-next');
            let pause             = slider.querySelector('.slider-play-pause');

            // If the slider doesn't have a prev/next we can abort the entire thing becuase it means there's no slider
            if (!next || !prev) return;

            // register a few events on the next button click
            next.addEventListener('click' , () => {

                // Move the slider forward when the arrow is clicked
                moveForward(slider)

                // Clear the slider interval
                clearInterval(slider.sliderInterval)

                if (isRunning(slider)) {
                    autoAdvance(slider,slider.sliderInterval)
                }
            });

            prev.addEventListener('click' , () => {
                moveBackwards(slider)
                clearInterval(slider.sliderInterval)
                slider.sliderInterval = null;

                if (isRunning(slider)) {
                    autoAdvance(slider)
                }
            });

            window.addEventListener('keyup' , (event) => {
                if (event.key == "ArrowRight") {
                    moveForward(slider)
                    clearInterval(slider.sliderInterval)

                    if (isRunning(slider)) {
                        autoAdvance(slider)
                    }
                }
                if (event.key == "ArrowLeft") {
                    moveBackwards(slider)
                    clearInterval(slider.sliderInterval)

                    if (isRunning(slider)) {
                        autoAdvance(slider)
                    }
                }
            })

            pause.addEventListener('click' , () => {


                if (isRunning(slider)) {
                    clearInterval(slider.sliderInterval)
                } else {
                    autoAdvance(slider,slider.sliderInterval)
                }

                slider.classList.toggle('slider-is-playing')
                slider.classList.toggle('slider-is-paused')

            })

            document.addEventListener("DOMContentLoaded" , () => {
                if (isRunning(slider)) {
                    autoAdvance(slider,slider.sliderInterval)
                }
            })


            // We need a few functions to make the slider works
            function moveForward (slider) {

                let currentSlide = getActiveSlide(slider);

                // Grab the target slide
                let targetSlide = currentSlide.nextElementSibling ?? currentSlide.parentElement.firstElementChild

                // Swap the classes
                currentSlide.classList.remove('slider-slide-active')
                targetSlide.classList.add('slider-slide-active')

            }

            // Move the slider backwards
            function moveBackwards (slider) {

                let currentSlide = getActiveSlide(slider);

                // Grab the target slide
                let targetSlide = currentSlide.previousElementSibling ?? currentSlide.parentElement.lastElementChild

                // Swap the classes
                currentSlide.classList.remove('slider-slide-active')
                targetSlide.classList.add('slider-slide-active')

            }

            // Set the auto advance
            function autoAdvance (slider) {

                slider.sliderInterval = setInterval(function() {
                    moveForward(slider)
                } , 2000)
            }

            // Check if the slider has to run
            function isRunning (slider) {
                return slider.classList.contains('slider-is-playing')
            }

            function getActiveSlide (slider) {
                return slider.querySelector('.slider-slide-active');
            }

        }




    },

    newsletterPopup : () => {

        // Grab the container
        let container = document.querySelector('.✉️-container')

        // check if there's something in the localstorage
        if (!localStorage.newsletter) {

            // Show the modal
            container.showModal();

            // Store the current date in the localstorage
            localStorage.newsletter = Date.now();

        }

        if (localStorage.newsletter) {

            let diff = Math.round((Date.now() - localStorage.newsletter) / 1000)

            if (diff > 2592000) {

                // Show the modal
                container.showModal();

                // Update the storage value
                localStorage.newsletter = Date.now();

            }

        }

    }


}

// Launch The Script
Site.liftoff()


